.theme-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px 0;
  padding: 0 20px;
}
.theme-buttons span {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 15px;
  border-radius: 50%;
}
.theme-buttons span:hover {
  filter: invert(10%);
}
.theme-buttons span.active::after {
  content: '';
  display: block;
  position: relative;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  width: 4px;
  height: 7px;
  left: 7px;
  top: 4px;
}
/* .mode-toggle {} */
.mode-toggle .mode-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
@media (min-width: 576px) {
  .theme-selector {
    padding: 0;
    margin: 20px 0;
  }
}