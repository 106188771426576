.App {
    min-height: 100%;
}
.App.dark {
    background: #333;
    color: #e4e4e4;
}
.App .app-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.App .app-loading .spinner-border {
    width: 3.5rem;
    height: 3.5rem;
    border-width: 3px;
}
