.searchbar {
  position: relative;
}
.search-input {
  padding: 8px 16px 9px 16px;
  border: 1px solid #fff !important;

}
.search-input:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #fff !important;
}
.searchbar .search-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: .4;
  right: 8px;
  top: 9px;
}