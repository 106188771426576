.header {
  padding: 1.4rem 20px;
  margin-bottom: 0;
  border-bottom: none;
  background: #8A39E1;
  color: #fff;
  /* box-shadow: 0 3px 6px 0 rgba(140,152,164,.25); */
}
.navbar {
  max-width: 1200px;
  margin: 0 auto;
}
.navbar-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  font-weight: 700;
}
.brand:hover {
  color: #fff;
  text-decoration: none;
}
.nav-list {
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
  max-width: 1200px;
}
.nav-list li {
  border-bottom: 1px solid rgba(255,255,255,.2);
  margin-top: 60px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.nav-list li:last-child {
  border: none;
  margin-top: 0;
  margin-bottom: 0;
}
.nav-item {
  display: block;
  color: rgba(255, 255,255, 1);
  font-size: 1.25rem;
  text-decoration: none;
  color: #fff;
  text-decoration: none;
  padding: 9px 16px 9px 16px;
  border: 2px solid #fff;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
}
.nav-item :hover {
  color: #fff;
  text-decoration: none;
}
.nav-item-active {
  color: #fff;
}
.burger {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 9px 5px 7px 5px;
  min-width: 76px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 15px;
}
/* .burger {
  background: transparent;
  border: 0 solid #fff;
  color: #fff;
  padding: 9px 2px 7px 0;
  border-radius: 0;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;
  opacity: .7;
} */
.burger:hover {
  opacity: 1;
}
.burger:focus {
  outline: none !important;
}
.authBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  max-width: 1200px;
  width: calc(100% - 40px);
  margin: 0 auto;
  font-size: 16px;
  font-weight: 300;
}


.searchbar-desktop {
  display: none;
}
.searchbar-mobile {
  display: block;
}
@media (min-width: 768px) {
  .header {
    padding: 1.8rem 20px;
    margin-bottom: 0;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .nav-list li {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
  .nav-item {
    margin-left: 0;
  }
  .burger {
    display: none;
  }
  .authBar {
    padding: 12px 0;
  }
  .searchbar-desktop {
    display: block;
    width: 40%;
  }
  .searchbar-mobile {
    display: none;
  }
}
