.search {}
.search-title {
    text-align: center;
    margin-bottom: 60px;
    font-weight: 300;
}

/* dark mode */
.dark .search .search-title {
  color: #fff;
}
.dark .search .loading, 
.dark .search .error
.dark .search .empty {
  color: #fff;
}

@media (max-width: 575.98px){
  .search .loading, 
  .search .error {
    margin: 0 auto;
    padding: 30px 0;
    background: #fff;
  }
  .dark .search .loading, 
  .dark .search .error {
    background: #555; 
    color: #fff;
  }
}

@media (max-width: 767.98px){
    .search-title {
      margin-top: 40px;
      margin-bottom: 40px;
    }
}

@media (max-width: 575.98px){
  .search-title {
    margin-top: 0;
    margin-bottom: 1px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  /* dark mode */
  .dark .search .empty {
    background: #555;
    color: #fff;
  }
}