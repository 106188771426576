@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

/* base styles */
:root {
  font-size: 16px;
}
html, body, #root {
  height: 100%;
}
body {
  font-family: 'Mulish', sans-serif;
  margin: 0;
  font-size: 1.4em;
  background: #dfdfdf;
  color: #333;
}
a {
  text-decoration: none;
}
a.dotted {
  border-bottom: 1px dotted;
  color: #8A39E1;
  text-decoration: none;
}
h1,h2,h3,p {
  margin: 0;
}
label {
  font-weight:  300;
}
b, strong {
  font-weight: 700;
}
.flex-box-baseline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.text-center {
  text-align: center;
}
input {
  color: #333;
  padding: 9px 16px 7px 16px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  font-weight: 300;
}
input:focus {
  border: 2px solid #8A39E1 !important;
  box-shadow: none !important;
  outline: none !important;
}
.page-title {
  text-align: center;
  margin: 40px auto;
  color: #333;
}
.loading, .error {
  text-align: center;
  margin: 40px auto;
  font-weight: 300;
}

/* dark theme */
.dark .loading, .dark .error {
  color: #fff; 
}

.error-field {
  border-color: #dc3545;
}
.error-feedback {
  color: #dc3545;
  font-size: 16px;
  font-style: normal;
} 
.hidden-small {
  display: none;
}
.empty {
  text-align: center;  
  font-weight: 300; 
}
.dark .empty {
  color: #fff;
}
@media (min-width: 768px){
  .hidden-small {
      display: block;
  }
}
@media (max-width: 767.98px){
  .empty {
    margin-top: 60px;
  }
}

@media (max-width: 575.98px){
  .empty {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0;
    background: #fff;
  }
  .dark .empty {
    background: #555;
  }
}

.fw-thin { font-weight: 100 !important; }
.fw-extra-light { font-weight: 200 !important; }
.fw-light { font-weight: 300 !important; }
.fw-normal { font-weight: 400 !important; }
.fw-medium { font-weight: 500 !important; }
.fw-semi-bold { font-weight: 600 !important; }
.fw-bold { font-weight: 700 !important; }
.fw-extra-bold { font-weight: 800 !important; }
.fw-black { font-weight: 900 !important; }

/* Flat theme */
/* * {
  border-radius: 0 !important;
}
.theme-buttons div {
  border-radius: 50% !important;
}
.recipe li,
.ing-tag {
  border-radius: 50px !important;
} */
* {
  box-shadow: none !important
}
.Toastify__toast {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05) !important;
}

/* vertical container */
.container-vertical {
  display: flex;
    flex-direction: column;
    min-height: calc(100vh - 280px);
}
.container-vertical-content {
  flex-grow: 1;
}

/* Toastify */
.Toastify__close-button {
  margin-top: 0 !important;
}
.Toastify__toast-container {
  font-size: 16px!important;
  font-weight: 300 !important;
  width: 100% !important;
  min-width: 320px !important;
}
.Toastify__toast-theme--dark {
  background: #333 !important;
}
@media (min-width: 576px) {
  .Toastify__toast-container {
    width: calc(100% - 32px) !important;
  }
}
@media (min-width: 768px) {
  .Toastify__toast-container {
      width: auto !important;
  }
}

/* print out json data */
.dark pre {
  color: #fff;
}

/* spinner border */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .15em solid currentColor;
  border-right-color: currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}
@keyframes spinner-border {
    to { transform: rotate(360deg); }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

/* SimpleMdeReact */
.EasyMDEContainer .CodeMirror {
  height: 220px !important;
}
.EasyMDEContainer .CodeMirror.CodeMirror-fullscreen {
  height: auto !important;
}
.EasyMDEContainer .CodeMirror-scroll {
  min-height: auto !important;
}
.error-field .EasyMDEContainer .CodeMirror {
  border-color: #dc3545;
}
.EasyMDEContainer .CodeMirror-code{
  font-size: 18px;
}
/* dark mode */
.dark .EasyMDEContainer .CodeMirror {
  background-color: #555;
  color: #fff;
}
.dark .EasyMDEContainer .editor-toolbar button .fa::before {
  color: #e4e4e4;
}
.dark .EasyMDEContainer .editor-toolbar i.separator {
  border-left: 1px solid rgba(228, 228, 228, .4);
  border-right: none;
}
.dark .editor-toolbar .easymde-dropdown:hover,
.dark .editor-toolbar .easymde-dropdown:focus,
.dark .editor-toolbar .easymde-dropdown:active, 
.dark .editor-toolbar button:hover,
.dark .editor-toolbar button:focus,
.dark .editor-toolbar button:active {
  background-color: #555;
  border-color: #e4e4e4;
}
.dark .editor-toolbar button.active {
  background-color: #4a4a4a;
  border-color: #e4e4e4;
}
.dark .editor-statusbar {
  color: #ddd;
}
.dark .editor-toolbar.fullscreen {
  background: #555;
}
.dark .editor-preview {
  background:#4a4a4a
}



select {
  padding: 12px 20px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
}