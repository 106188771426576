.recipe-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}
.recipe-list .card {
  background: #fff;
  color: #333;
  padding: 20px 20px 27px 20px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}
/* .recipe-list .card:hover {
  transform: rotate(3deg);
} */
.recipe-list .card h3 {
  color: #555;
  margin-bottom: 6px;
  padding-right: 60px;
}
.recipe-list .card p {
  color: #999;
  font-size: 1.2rem;
}
.recipe-list .card div {
  color: #555;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}
.recipe-list .card a {
  color: #555;
  text-decoration: none;
  display: block;
  background: #e2e2e2;
  font-size: 0.9em;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}
.recipe-list .card a:hover {
  filter: brightness(90%);
}
.recipe-list .card .action {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  filter: invert(60%);
  cursor: pointer;
}
.recipe-list .card .action.pen {
  right: 45px;
}
.recipe-list .card .action.trash {
  right: 15px;
}
.recipe-list .card .action:hover {
  filter: invert(40%);
}

/* dark mode */
.dark .recipe-list .card {
  background: #555;
}
.dark .recipe-list .card p,
.dark .recipe-list .card h3,
.dark .recipe-list .card div {
  color: #e4e4e4;
}
.dark .recipe-list .card .action {
  filter: invert(80%);
}
.dark .recipe-list .card .action:hover {
  filter: invert(100%);
}

@media (max-width: 992px) {
  .recipe-list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}

@media (max-width: 768px) {
  .recipe-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .recipe-list {
    grid-gap: 1px;
    margin: 0 auto;
    
  }
  .recipe-list .card {
    border-radius: 0;
    transition: none;
  }
  .recipe-list .card:hover {
    transform: none;
  }
}