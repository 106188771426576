/* basic styles */
.skeleton-article .recipe-list .card div {
    margin: 0;
}
.skeleton {
    background-color: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}
.skeleton.text {
    width: 100%;
    height: 12px;
    margin: 12px 0 !important;
    opacity: .5;
}
.skeleton.title {
    width: 70%;
    height: 20px;
    margin: 0 20px 0;
}
.skeleton.subtitle {
    width: 70%;
    height: 15px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    opacity: .7;
}
.skeleton.button {
    width: 40%;
    height: 45px;
    margin: 30px auto 0 !important;
    border-radius: 5px;
    opacity: .4;
}
.skeleton-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    background-color: transparent;
}
.skeleton-center {
    display: block;
    margin: 0 auto !important;
}

@media (min-width: 576px) {
    .skeleton-wrapper {
        border-radius: 10px;
    }
}

.skeleton-w-10 { width: 10%!important; }
.skeleton-w-20 { width: 20% !important; }
.skeleton-w-30 { width: 30% !important; }
.skeleton-w-40 { width: 40% !important; }
.skeleton-w-50 { width: 50% !important; }
.skeleton-w-60 { width: 60% !important; }
.skeleton-w-70 { width: 70% !important; }
.skeleton-w-80 { width: 80% !important; }
.skeleton-w-90 { width: 90% !important; }
.skeleton-w-100 { width: 100% !important; }

.skeleton-t-space-0 { margin-top: 0 !important; }
.skeleton-b-space-0 { margin-bottom: 0 !important; }
.skeleton-t-space-5 { margin-top: 5px !important; }
.skeleton-b-space-5 { margin-bottom: 5px !important; }
.skeleton-t-space-10 { margin-top: 10px !important; }
.skeleton-b-space-10 { margin-bottom: 10px !important; }
.skeleton-t-space-10 { margin-top: 10px !important; }
.skeleton-b-space-10 { margin-bottom: 10px !important; }

/* themes */
.dark .skeleton.title {
    opacity: .4;
}
.dark .skeleton.subtitle {
    opacity: .3;
}
.dark .skeleton.text {
    opacity: .2;
}
.dark .skeleton.button {
    opacity: .3;
}

/* animation effects */
.shimmer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}
.shimmer {
    width: 50%;
    height: 100%;
    background-color:rgba(255, 255, 255, .2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, .05);
}
.dark .shimmer {
    background-color:rgba(85, 85, 85, .2);
    box-shadow: 0 0 30px 30px rgba(85, 85, 85, .05);
}
@keyframes loading {
    0% {transform: translateX(-150%);}
    50% { transform: translateX(-60%);}
    100% {transform: translateX(150%);}
}

/* visibility */
.skeleton-hide-lg {
    display: none;
}
.skeleton-hide-md {
    display: none;
}
@media (min-width: 768px) {
    .skeleton-hide-md {
        display: block;
    }
}
@media (min-width: 992px) {
    .skeleton-hide-lg {
        display: block;
    }
}
