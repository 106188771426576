.container {
    max-width: 1200px;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px;
}

@media (max-width: 576px) {
    .container {
      max-width: initial;
      margin: 0 auto;
      padding: 0;
      border-radius: 0;
      width: 100%;
    }
}