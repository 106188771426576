.recipe {
  position: relative;
  /* text-align: center; */
  background: #fff;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
}
.recipe ul.ingredients {
  display: inline-block;
  padding: 0;
  justify-content: center;
  margin-top: 8px;
}
.recipe ul.ingredients li {
  display: inline-block;
  list-style-type: none;
  margin-right: 10px;
  color: #555;
  padding: .3rem .9rem .4rem;
  margin: 6px 5px 0 0;
  border-radius: 20px;
  background-color: rgb(226, 226, 226, .5);
  font-size: 12px;
}
.subheading {
  font-size: 18px;
  font-weight: 300;
}
.recipe .method {
  text-align: left;
  line-height: 1.5em;
  font-weight: 300;
  font-size: .9em;
  margin-top: 25px;
  border-top: 1px solid rgba(228, 228, 228, .5);
  padding-top: 30px;
}
.recipe .method h1,
.recipe .method h2,
.recipe .method h3,
.recipe .method h4,
.recipe .method h5,
.recipe .method h6 {
  margin-top: 0;
  margin-bottom: 10px;
}
.recipe .method h1 {
  font-size: 26px;
}
.recipe .method h2 {
  font-size: 24px;
}
.recipe .method h3 {
  font-size: 22px;
}
.recipe .method h4 {
  font-size: 20px;
}
.recipe .method h5 {
  font-size: 18px;
}
.recipe .method h6 {
  font-size: 16px;
}
.recipe .method p {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 27px;
}
.recipe .method ul,
.recipe .method ol {
  padding-left: 35px;
  font-size: 18px;
}
.recipe .action {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  filter: invert(60%);
  cursor: pointer;
}
.recipe .action.pen {
  right: 45px;
}
.recipe .action.trash {
  right: 15px;
}
.recipe .action:hover {
  filter: invert(40%);
}

/* dark mode */
.dark .recipe {
  background: #555;
  color: #e4e4e4;
}
.dark .recipe h2 {
  color: #fff;
}
.dark .recipe ul.ingredients li {
  background-color: rgb(226, 226, 226, 1);
}
.dark .recipe .action {
  filter: invert(80%);
}
.dark .recipe .action:hover {
  filter: invert(100%);
}

@media (max-width: 576px) {
  .recipe {
    padding: 20px 20px;
    border-radius: 0;
  }
}