/* modal */
.confirm-bg {
  position: fixed;
  display: none; /* flex | none */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  overflow: hidden; /* disable scrolling */
  z-index: 2; /* higher than all other items, but lower than 
    the confirm box*/
}
/* dark mode */
.dark .confirm-bg {
   background-color: rgba(255, 255, 255, .5)
}

.confirm-box {
  display: none; /* flex | none */
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  max-width: 450px;
  width: calc(100% - 40px);
  top: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
  border-radius: 10px;
  padding: 0;
  z-index: 5; /* Higher than the z-index of the background */
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
}
.confirm-box-header {
    padding: 16px 22px 16px 22px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confirm-box-header .title {
    font-size: 22px;
    color: #333;
    font-weight: 700;
}
.confirm-box-header .close {
    cursor: pointer;
    font-size: 28px;
    line-height: 28px;
    color:rgba(0, 0, 0, .5);
}
.confirm-box-header .close:hover {
    color:rgba(0, 0, 0, .8);
}
.confirm-box-body {
  display: flex;
  color: #555;
  padding: 0 22px;
  margin: 30px 0 40px 0;
  line-height: 2rem;
  font-size: 1.1rem;
  font-weight: 300;
}
.confirm-box-footer {
  padding: 13px 22px 13px 22px;
  border-top: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-end;
}
.confirm-box-footer .confirm-button {
  display: inline-flex;
  background-color: #dc3545;
  color: white;
  padding: 11px 16px 9px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 5px;
  box-sizing: border-box;
}
.confirm-box-footer .confirm-button:hover {
  background-color: #d12434;
  cursor: pointer;
}
.confirm-box-footer .cancel-button {
  display: inline-flex;
  background-color: #f8f9fa;
  color: #333;
  padding: 11px 16px 9px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 5px;
  box-sizing: border-box;
}
.confirm-box-footer .cancel-button:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

/* dark mode */
.dark .confirm-box {
    background-color: #555;
}
.dark .confirm-box-header,
.dark .confirm-box-footer {
    border-color: #5e5e5e;
}
.dark .confirm-box-header .title {
    color: #e4e4e4
}
.dark .confirm-box-header .close {
    color: rgba(228, 228, 228, .5)
}
.dark .confirm-box-header .close:hover {
    color: rgba(228, 228, 228, .8)
}
.dark .confirm-box-body {
    color: #e4e4e4
}
.dark .confirm-box-footer .cancel-button {
   background-color: #515151;
   color: rgba(228, 228, 228, .5);
}
.dark .confirm-box-footer .cancel-button:hover {
   background-color: #5f5f5f;
}

/* animations */
.fadeIn {
  -webkit-animation: fadeIn 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeIn 500ms ease-in-out;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }    
  100% {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  100% {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}
.fadeOut {
  -webkit-animation: fadeOut 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeOut 500ms ease-in-out;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
    display: block;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
    display: block;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}