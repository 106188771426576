/* paging */
.paging {
  padding: 1em 0;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.paging-carrier {
  box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 5%);
  border-radius: 23px !important;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  width: calc(100% - 40px);
  overflow: hidden;
}
.paging-carrier svg {
  padding: 8px 20px 6px 20px;
  height: 34px;
  width: 34px;
  stroke:rgba(51, 51, 51, .3)
}
.paging-carrier a svg {
  stroke:rgba(51, 51, 51, 1)
}
.paging-carrier div:first-child {
  border-right: 1px solid #f5f5f5;
}
.paging-carrier div:nth-child(3) {
  border-left: 1px solid #f5f5f5;
}
.paging-carrier > div {
  width: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paging-carrier span {
  color: rgba(51, 51, 51, 1);
  font-size: 21px;
  font-weight: 600;
}
.paging-carrier a {
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paging-carrier a:hover {
  background-color: rgba(0, 0, 0, .02);
}
.paging-control {
  padding: 0 20px;
}
.paging-header {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 0 20px;
  font-weight: 300;
}

/* dark */
.dark .paging-carrier {
  background-color: #555;
  border: 1px solid #5a5a5a;
  box-shadow: 0 0.1rem 0.2rem 0 rgb(255 255 255 / 5%);
}
.dark .paging-carrier div:first-child {
  border-right: 1px solid #5a5a5a;
}
.dark .paging-carrier div:nth-child(3) {
  border-left: 1px solid #5a5a5a;
}
.dark .paging-carrier a:hover {
  background-color: rgba(255, 255, 255, .02);
}
.dark .paging-carrier span {
  color: #e4e4e4;
}
.dark .paging-carrier a:hover svg {
  stroke:#fff;
}
.dark .paging-carrier svg {
  stroke:rgba(228, 228, 228, .3);
}
.dark .paging-carrier a svg {
  stroke:rgba(228, 228, 228, 1);
}
.dark .paging-header {
  color: #e4e4e4;
}

@media (min-width: 576px) {
  .paging-carrier {
    min-width: 300px;
    width: auto;
  }
  .paging-header {
    padding: 0;
  }
}