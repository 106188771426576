.update {
  color: #555;
  /* max-width: 480px; */
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
}
.update label span {
  display: block;
  margin: 30px 0 7px;
  font-size: 18px;
}
.update p {
  margin-top: 10px;
  font-size: 0.8em;
}
.update .btn {
  display: block;
  min-width: 100px;
  font-size: 1em;
  color: #fff;
  padding: 11px 20px 9px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #8A39E1;
  cursor: pointer;
  text-decoration: none;
  margin: 20px auto;
  font-weight: 300;
}
.ingredients {
  display: flex;
  align-items: center;
}
.ingredients button {
  margin: 0 0 0 10px;
}
.ing-tag {
  display: inline-block;
  margin: 4px 5px 1px 0;
  border-radius: 20px;
  background-color: rgb(226, 226, 226, .5);
  padding: 1px 12px 3px 12px;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
}
.ing-tag:hover {
  background-color: rgb(226, 226, 226, 1);
}

/* dark mode */
.dark .update {
  background: #555;
  color: #e4e4e4;
}
.dark .update h2 {
  color: #fff;
}
.dark .update li {
  background-color: rgb(226, 226, 226, 1);
}
.dark .update .ing-tag {
  background-color: rgb(226, 226, 226, 1);
  color: #555;
}
.dark .update .ing-tag:hover {
  background-color: rgb(226, 226, 226, .8);
  color: #444;
}
.dark .update input,
.dark .update textarea {
  background: #555;
  color: #fff;
}

@media (max-width: 768px) {
  .update {
    margin: 0 auto;
    width: calc(100% - 80px);
    max-width: initial;
  }
}

@media (max-width: 576px) {
  .update {
    margin: 0 auto;
    padding: 20px 20px;
    width: calc(100% - 40px);
    border-radius: 0;
  }
}