.disclaimer {
    margin: 100px 0 0 0;
    text-align: center;
    padding: 0 20px 50px 20px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
}
.disclaimer .badge {
    display: inline-block;
    background-color: rgb(0, 0, 0, .4);
    color: #fff;
    font-size: 0.7em;
    margin: 4px 3px;
    padding: 0.25rem 0.8rem 0.25rem 0.8rem;
    border-radius: 10px !important;
}
.disclaimer .copyright {
    margin-bottom: 35px;
}
.disclaimer .keywords {
    margin-bottom: 15px;
}

/* dark theme */
.dark .disclaimer {
    color: #e4e4e4;
}
.dark .disclaimer .badge {
    background-color: rgb(255, 255, 255, .85);
    color: #555;
}

@media (max-width: 575.98px){
    .disclaimer {
        padding-bottom: 35px;
    }
}